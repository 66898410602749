import { app as appInitialState } from '../store/initialState';
let jwt = JSON.parse(localStorage.getItem('jwt'))
export const API_ENDPOINT = `${appInitialState.API_ENDPOINT}`;
export const GET_CONFIG = {
  method: 'GET',
  mode: 'cors',
};
export const PUT_CONFIG = {
  method: 'PUT',
  mode: 'cors',
  headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${jwt}` }
}
export const POST_CONFIG = {
  method: 'POST',
  mode: 'cors',
  
}

export const handleErrors = (response) => {
  if (!response.ok) {
    throw new Error(response.statusText || response.message);
  }
  return response;
}