import { createAsyncThunk as thunk, createSlice } from '@reduxjs/toolkit';

const getUserRemote = async () => {
  let jwt = JSON.parse(localStorage.getItem('jwt'))
  const meConfig = {
    method: 'GET',
    mode: 'cors',
    headers: {Accept: 'application/json',Authorization: `Bearer ${jwt}`}
  };
  const meResp = await fetch(
    'https://us-kardia-staging.alivecor.com/i/v1/me',
    meConfig
  );
  const me = await meResp.json();
  console.log(me);
  return { user: me.profile };
};

export const getUser = thunk('user/get', getUserRemote);

export const user = createSlice({
  name: 'User',
  initialState: {},
  reducers: {},
  extraReducers: {
    [getUser.fulfilled]: (state, action) => {
      return action.payload.user;
    }
  }
});

export default user.reducer;