import React, { useState, useEffect } from "react";
import clone from "rfdc/default";
// import { useSelector, useDispatch } from 'react-redux';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextareaAutosize,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  Backdrop,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import AddCircleIcon from "@material-ui/icons/AddCircle";
// import { toggleEpisodeUpdateDialog } from '../../store/app';
// import { get as getEpisode, updateEpisode } from '../../store/episodes';
const backdropStyles = makeStyles({
  root: {
    position: "absolute",
    zIndex: 9999,
  },
});
const containerStyles = makeStyles({
  root: {
    position: "relative",
  },
});

export default function Update(props) {
  /**
   * Setting local states and styles
   */
  const [questions, setQuestions] = useState([]);
  const [questionText, updateQuestionText] = useState([]);
  const [ansText, updateAnsText] = useState("");
  const [answers, setAnswers] = useState([]);
  const [episode, updateEpisode] = useState(props.episode);
  const [submitting, updateSubmitting] = useState(false);
  const backdropClasses = backdropStyles();
  const containerClasses = containerStyles();
  useEffect(() => updateEpisode(props.episode), [props.episode]);
  /**
   * populating local states to edit an episode
   * Local states used:
   * Questions , Answers, questionTexts, answerTexts
   * not consuming states answers and answerTexts right now
   */
  useEffect(() => {
    // console.log(episode);
    console.log("updating episode");
    if (episode.question && episode.answer) {
      // console.log("episode is ", episode);
      if (episode.question) {
        console.log('inside setting question')
        setQuestions(
          episode.question.split(",").map((q) => {
            return { q: q, edit: false };
          })
        );
        updateQuestionText(
          episode.question.split(",").map((q) => {
            return q;
          })
        );
      }
      if (episode.answer) {
        setAnswers(
          episode.answer.split(",").map((ans) => {
            return { ans: ans, edit: false };
          })
        );
      }
    }
  }, [episode]);

  /**
   * @desc Adds a new question in the list with editing as true. Updates questions and questionText.
   */
  const addNewQuestion = () => {
    setQuestions((q) => {
      q.push({ q: "", edit: true });
      return [...q];
    });
    updateQuestionText((qt) => {
      qt.push("Untitled");
      return [...qt];
    });
  };
  /**
   * @desc handles the text changes in each question
   * @param {*} index
   * @param {*} value
   */
  const updateQuestionTextHandler = (index, value) => {
    let upqt = questionText;
    upqt[index] = value;
    updateQuestionText([...upqt]);
  };

  /**
   * @desc toggles the question from edit state to save state
   * @param {*} index
   */
  const toggleEditQuestion = (index) => {
    console.log("edit");
    if (!questions[index].edit) {
      // updateQuestionText(questions[index].q)
      setQuestions((q) => {
        q[index].edit = true;
        return [...q];
      });
    } else {
      setQuestions((q) => {
        q[index].edit = false;
        q[index].q = questionText[index];
        return [...q];
      });
    }
  };

  /**
   * Deletes a question from the list, this is temporary(local state change) until you hit save and submit
   * @param {*} index
   */
  const deleteQuestionFromArray = (index) => {
    setQuestions((q) => {
      let questionToDel = questions[index];
      let qs = q.filter((qs) => qs.q !== questionToDel.q);
      return [...qs];
    });
    updateQuestionText((q) => {
      let questionToDel = questions[index].q;
      let qs = q.filter((q) => q != questionToDel);
      return [...qs];
    });
  };

  /**
   * This submits the form with the existing episode body
   * @param {*} event
   */
  const submit = async (event) => {
    updateSubmitting(true);
    event.preventDefault();
    const updateAnswerEpisode = { ...episode };
    updateAnswerEpisode.intent.sampleUtterances = questionText.filter(q=>q!=='Untitled');
    if(props.isNew){
      updateAnswerEpisode.intent.closingResponses = ['Empty Answer']
    }
    const updateResp = props.isNew
      ? await props.create(episode)
      : await props.update(episode);
    updateSubmitting(false);
    // dispatch(updateEpisode(episodeUpdate));
    // toggle();
  };

  /**
   * Handler to set intent name
   * @param {*} ev
   */
  const setIntentName = (ev) => {
    const val = ev.target.value ? ev.target.value.replace(/\s/g, "") : null;
    const updatedEpisode = clone(episode);
    updatedEpisode.intent.metadata.name = val;
    updateEpisode(updatedEpisode);
  };

  /**
   * Handler to set status
   * @param {*} ev
   */
  const setStatus = (ev) => {
    const val = ev.target.value || null;
    updateEpisode({ ...episode, status: val });
  };

  /**
   * Handler to set diligence
   * @param {*} ev
   */
  const setDiligence = (ev) => {
    const val = ev.target.value || null;
    updateEpisode({ ...episode, dueDiligence: ev.target.value });
  };

  /**
   * Handler to set answer
   * @param {*} ev
   */
  const setAnswer = (ev) => {
    // props.update({...episode, answer: ev.target.value});
    const updatedAnswerEpisode = { ...episode };
    updatedAnswerEpisode.intent.closingResponses[0] = ev.target.value || null;
    updatedAnswerEpisode.answer =
      updatedAnswerEpisode.intent.closingResponses[0];
    updateEpisode(updatedAnswerEpisode);
  };

  const cleanup=()=>{
    setQuestions([]);
    updateQuestionText([]);
    props.updateOpen(false)
  }
  return (
    <div className={containerClasses.root} data-garbage="garbage">
      {" "}
      <Dialog
        open={props.open}
        onClose={()=>cleanup()}
        aria-labelledby="form-dialog-title"
      >
        <Backdrop
          open={submitting}
          className={backdropClasses.root} /*  onClick={handleClose} */
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div id="errors">{props.errors}</div>
        <DialogTitle id="form-dialog-title">{props.isNew?'Add Episode':'Edit Episode'}</DialogTitle>
        <form onSubmit={submit}>
          <DialogContent dividers>
            <List>
              Question:
              <br />
           
              {questions &&
                questions.map((s, index) => (
                  <ListItem>
                    {s.edit ? (
                      <FormControl fullWidth={true}>
                        <TextField
                          id="standard-basic"
                          label="Enter text"
                          value={questionText[index]}
                          onChange={(e) =>
                            updateQuestionTextHandler(index, e.target.value)
                          }
                          disabled={submitting}
                        />
                        <br />
                      </FormControl>
                    ) : (
                      <ListItemText
                        primary={s.q}
                        // secondary={secondary ? 'Secondary text' : null}
                      />
                    )}
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="start"
                        aria-label="comments"
                        onClick={() => toggleEditQuestion(index)}
                      >
                        {s.edit ? <SaveIcon /> : <EditIcon />}
                      </IconButton>
                    </ListItemSecondaryAction>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge=""
                        aria-label="delete"
                        style={{ marginRight: "-30px" }}
                        onClick={() => deleteQuestionFromArray(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              <ListItem>
                <AddCircleIcon onClick={() => addNewQuestion()} />
              </ListItem>
            </List>
            <FormControl fullWidth={true}>
              <TextField
                id="standard-basic"
                label="Intent Name"
                value={episode.intent.metadata.name}
                onChange={setIntentName}
                disabled={submitting}
              />
              <br />
            </FormControl>
            <InputLabel id="answer-label">Answer:</InputLabel>
            <TextareaAutosize
              value={episode.answer}
              onChange={setAnswer}
              rowsMin="4"
              id="answer"
              label="Answer"
              style={{ width: "100%" }}
              disabled={submitting || props.isNew}
            />
            <FormControl fullWidth={true}>
              <InputLabel id="status-label">Status:</InputLabel>
              <Select
                labelId="status-label"
                value={episode.status || ""}
                onChange={setStatus}
                disabled={submitting}
              >
                <MenuItem value={"resolved"}>Resolved</MenuItem>
                <MenuItem value={"reviewed"}>Reviewed</MenuItem>
                <MenuItem value={"open"}>Open</MenuItem>
                <MenuItem value={"legal"}>Legal</MenuItem>
                <MenuItem value={""}>None</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth={true}>
              <InputLabel id="diligence-label">Due Diligence Type:</InputLabel>
              <Select
                labelId="diligence-label"
                value={episode.dueDiligence || ""}
                onChange={setDiligence}
                disabled={submitting}
              >
                <MenuItem value={"technical"}>Technical</MenuItem>
                <MenuItem value={"medical"}>Medical</MenuItem>
                <MenuItem value={"none"}>None</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={()=>cleanup()}
              color="primary"
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={submitting}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

