import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import { CircularProgress, Button } from '@material-ui/core';

function LoadingButton(props) {
  const [loading, updateLoadingState] = useState(props.loading);
  const { onClick, label } = props;
  useEffect(() => (updateLoadingState(props.loading)), [props.loading]);
  return (
    <Button variant="contained" onClick={onClick} disabled={loading}>
      {loading ? <CircularProgress color="secondary" size={16} /> : props.label}
    </Button>
  );
}

export default LoadingButton;