import React from 'react'
import {BrowserRouter as Router , Route } from 'react-router-dom'
import EpisodeScreen from './screens/EpisodeScreen'
import LoginScreen  from './screens/LoginScreen'
const App = () => {
  return (
    <div>
      <Router basename="/">
        <Route path="/" component={LoginScreen} exact/>
        <Route path="/episodes" component={EpisodeScreen} exact/>
      </Router>
     
    </div>
  )
}

export default App
