import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { Backdrop, CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { DataGrid, isOverflown } from '@material-ui/data-grid';
// import { getEpisodes } from '../../store/episodes';
// import { toggleEpisodeUpdateDialog } from '../../store/app';
import { makeStyles } from '@material-ui/core/styles';
const backdropStyles = makeStyles({
  root: {
    position: 'absolute',
    zIndex: 9999
  }
});

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    lineHeight: '24px',
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div
      ref={wrapper}
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue" >
        {value}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }} >
             {value.split(',').map(v=>
               <p>{v}</p>)}
            </Typography>
          </Paper>
        </Popper>
      )}
    </div>
  );
});


function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value ? params.value.toString() : ''}
      width={params.colDef.width}
    />
  );
}
const Episodes = (props) => {
  
  // const episodes = useSelector(state => state.episodes.map(episode => ({ ...episode, ...episode.recording })));
  // const [episodes, updateEpisodes] = useState([]);
  const [open] = useState(props.open);
  const [loading, updateLoadingState] = useState(props.loading);
  const backdropClasses = backdropStyles();
  const toggleEpisodeUpdateDialog = (episode) => {
    props.updateEpisodeChosen(episode);
    props.updateOpen(!open);
  }
  console.log('running episodes');
  // const dispatch = useDispatch();

  // const testEpisodesGet = dispatch(getEpisodes());
  // console.log('testEpisodesGet', testEpisodesGet);
  const columnsConfig = [
    { field: 'id',
      headerName: 'ID',
      width: 75,
      renderCell: (params) => {
        const onClick = (episode, ev) => {
          ev.preventDefault();
          toggleEpisodeUpdateDialog(episode);
        }
        return <a href="#" onClick={onClick.bind(null, params.row)}>{params.row.id}</a>
      }
    },
    { field: 'status', headerName: 'Status', flex: 1,renderCell: renderCellExpand },
    { field: 'question', headerName: 'Question', flex: 2,renderCell: renderCellExpand },
    { field: 'answer', headerName: 'Answer', flex: 2,renderCell: renderCellExpand },
    { field: 'intentName', headerName: 'Intent Name', flex: 1 },
    { field: 'helpful', headerName: 'Helpful', flex: 1 },
    { field: 'dueDiligence', headerName: 'Diligence Type', flex: 1 }/* ,
    { field: 'confidence', headerName: 'Confidence', flex: 1 } */
  ];

  useEffect(() => (updateLoadingState(props.loading)), [props.loading]);

  return (<div style={{ height: '100vh', width: '100%', position: 'relative' }}>
      <Backdrop open={loading} className={backdropClasses.root} /*  onClick={handleClose} */>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DataGrid
        rows={props.episodes}
        columns={columnsConfig}
        pageSize={10}
        /* checkboxSelection */
        disableSelectionOnClick
      />
    </div>);
}
export default Episodes;
