import clone from 'rfdc/default';
import { API_ENDPOINT, GET_CONFIG, PUT_CONFIG, handleErrors, POST_CONFIG } from './config';
const sample= require('../sample.json')
const base = `${API_ENDPOINT}bots/PA1EQHHUXJ/regions/en_US/episodes/`;//?detail=true';

export const episodeModel = {
  "createTime": null,
  "id": null,
  "updateTime": null,
  "auditTrail": {},
  "confidence": 0,
  "dueDiligence": null,
  "helpful": null,
  "recording": {
    "answer": null,
    "question": null
  },
  "status": null,
  "intent": {
    "closingResponses": [],
    "confirmationResponses": [],
    "sampleUtterances": [],
    "metadata": {
      name: ''
    }
  },
  "question":"",
  "answer":""
}

const shapeEpisode = episode => {
  let shapedEpisode = {...episode};
  if(!episode.intent) {
    shapedEpisode.intent = clone(episodeModel.intent);
    shapedEpisode.intent.sampleUtterances.push(episode.recording.question);
  }
  return shapedEpisode||episode;
}

export const getEpisodes = async (id) => {
  let jwt = JSON.parse(localStorage.getItem('jwt'))
  const config = {...GET_CONFIG, headers: {Accept: 'application/json',Authorization: `Bearer ${jwt}`}};
  const episodesResp = await fetch(`${base}${id||''}?detail=true`, config).then(handleErrors);
  const episodes = await episodesResp.json();
  // the below line is kept for development and loads sample.json
  // const episodes = sample 
  return episodes.map(episode => shapeEpisode(episode));
}

export const updateEpisode = async (episode) => {
  const config = {...PUT_CONFIG, body:JSON.stringify(episode)};
  const updateEpisodeResp = await fetch(`${base}${episode.id}`, config).then(handleErrors);
  return await updateEpisodeResp.json();
}
export const createEpisode = async (episode) => {
  let jwt = JSON.parse(localStorage.getItem('jwt'))
  const config = {...POST_CONFIG, headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${jwt}` }, body:JSON.stringify(episode)};
  const createEpisodeResp = await fetch(`${base}`, config).then(handleErrors);
  return await createEpisodeResp.json();
}

export const mapEpisodeForColumns = episode => {
  const mappedEpisode = {
    ...episode,
    ...episode.recording,
    ...episode.intent
  };
  if(!mappedEpisode.intent) {
    mappedEpisode.intent = clone(episodeModel.intent);
  }
  if(!mappedEpisode.intent.closingResponses) {
    mappedEpisode.intent.closingResponses = [];
  }
  if(!mappedEpisode.intent.sampleUtterances) {
    mappedEpisode.intent.sampleUtterances = [];
  }
  if(!mappedEpisode.intent.metadata) {
    mappedEpisode.intent.metadata = clone(episodeModel.intent.metadata);
  }
  if(!mappedEpisode.intent.metadata.name) {
    mappedEpisode.intent.metadata.name = '';
  }
  if(mappedEpisode.intent.closingResponses.length) {
    mappedEpisode.answer = mappedEpisode.intent.closingResponses.map(m=>m).join(';');
  }else{
    mappedEpisode.answer='Empty Answer'
  }
  if(mappedEpisode.intent.sampleUtterances.length>0) {
    mappedEpisode.question = mappedEpisode.intent.sampleUtterances.map(m=>m).join(',');
  }else{
    mappedEpisode.question="Empty Question"
  }
  mappedEpisode.intentName = mappedEpisode.intent.metadata.name;
  return mappedEpisode;
};
export const mapEpisodesForColumns = episodes => (episodes.map(mapEpisodeForColumns));