import { createAsyncThunk as thunk, createSlice } from '@reduxjs/toolkit';
import { bots as initialState } from './initialState';
const endpointBase = process.env.REACT_APP_ENDPOINT;
console.log(process.env);
const getBotsRemote = async () => {
  let jwt = JSON.parse(localStorage.getItem('jwt'))
  const authConfig = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
  console.log(jwt)
  const botsResp = await fetch(`${endpointBase}bot`,authConfig);
  const bots = await botsResp.json();
  return { bots };
};

export const getBots = thunk('bots/get', getBotsRemote);

export const bots = createSlice({
  name: 'Bots',
  initialState,
  reducers: {},
  extraReducers: {
    [getBots.fulfilled]: (state, action) => {
      return action.payload.bots;
    }
  }
});

export const { chosen } = bots.actions;
export default bots.reducer;