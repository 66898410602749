import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authUser } from '../store/auth';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grid, Paper, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AskKardia from '../assets/images/AskKardia.jpeg';

const useStyles = makeStyles((theme) => ({ 
  root:{
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '70px',
    borderRadius: '50px',
    boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formStyles:{
    minWidth: '338px' 
  },
  child:{
    marginLeft: 0, marginBottom: '10px' 
  },
  textField:{
    width: '100%' 
  }

}));

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { jwt, error: errorState } = useSelector((state) => state.auth);
  console.log(errorState);
  useEffect(() => {
    if (jwt) {
      history.push('/episodes');
    }
    if (errorState) {
      setError(true);
      setLoading(false);
    }
  }, [jwt, errorState]);

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(authUser({ email, password }));
  };
  const classes = useStyles();

  return (
    <div >
      <div
        className={classes.root}
      >
        <img src={AskKardia} alt="Ask Kardia" />
        <h2>Ask Kardia Admin Tool</h2>
        <form
         
          noValidate
          autoComplete='off'
          onSubmit={submitHandler}
          className={classes.formStyles}
        >
          {errorState && (
            <div className={classes.child}>
              {' '}
              <Alert severity='error'>{errorState}</Alert>
            </div>
          )}
          <div className={classes.child}>
            <TextField
              id='outlined-basic'
              label='Email'
              variant='outlined'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes.textField}
            />
          </div>
          <div className={classes.child}>
            <TextField
              id='outlined-basic'
              type='password'
              label='Password'
              variant='outlined'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.textField}
            />
          </div>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            disabled={!email || !password}
          >
            {loading ? <CircularProgress /> : 'Submit'}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
