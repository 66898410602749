import { createAsyncThunk as thunk, createSlice } from '@reduxjs/toolkit';
import { episodes as initialState, app as appInitialState } from './initialState';

const EPISODES_API_ENDPOINT = `${appInitialState.API_ENDPOINT}`;
const base = `${EPISODES_API_ENDPOINT}bots/PA1EQHHUXJ/regions/en_US/episodes/`;//?detail=true';
console.log(EPISODES_API_ENDPOINT);

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}


const getEpisodesRemote = async(id) => {

  const getConfig = {
    method: 'GET',
    mode: 'cors',
    headers: {Accept: 'application/json'}
  };
  const episodesResp = await fetch(`${base}${id||''}?detail=true`, getConfig);
  return episodesResp.body;
}
const updateResponseRemote = async (episode) => {
  try {
    const updateConfig = { 
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(episode),
      headers: { 'Content-Type': 'application/json' },
    }
    const updateEpisodeResp = await fetch(`${base}${episode.id}`, updateConfig);
    return updateEpisodeResp.data;
  } catch(e) {
    handleErrors(e);
  }
}

export const getEpisodes = thunk('episodes/get-all', getEpisodesRemote);
export const updateEpisode = thunk('episodes/put', updateResponseRemote);

export const slice = createSlice({
  name: 'Episodes',
  reducers: {
    get: (state, action) => {
      const { id } = action.payload;
      return state.find(episode => episode.id === id);
    }
  },
  extraReducers: {
    [getEpisodes.fulfilled]: (state, action) => {
      state.episodes = action.payload;
    },
    [updateEpisode.fulfilled]: (state, action) => {
      return action;//.payload.episode;
    }
  },
  initialState
});
export const { get } = slice.actions;
export default slice.reducer;