import React from 'react';
import Logo from '../logo';
import User from '../user';
import Version from '../version';
import Bots from '../bots';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'relative'
  }
}));


const Sidebar = () => {
  const classes = useStyles();
  return (

      <Drawer className={classes.drawer} classes={{paper: classes.drawerPaper}} open={true} variant="permanent">
        <Logo />
        <User />
        <Bots />
        <Version />
      </Drawer>
  )
};

export default Sidebar;