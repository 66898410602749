const environment = process.env.REACT_APP_ENVIRONMENT;
console.log('environment', environment, process.env.NODE_ENV);
let bots = [];
if(environment === 'development') {
  bots = [
    {
      "createTime": 1619658508360,
      "id": "PA1EQHHUXP",
      "updateTime": 1619658508360,
      "locales": [
        {
          "id": "es_US",
          "name": "Spanish (US)"
        },
        {
          "id": "en_US",
          "name": "English (US)"
        }
      ],
      "name": "KardiaHelpDesk"
    },
    {
      "createTime": 1619658508360,
      "id": "PA1EQHHUXJ",
      "updateTime": 1619658508360,
      "locales": [
        {
          "id": "es_US",
          "name": "Spanish (US)"
        },
        {
          "id": "en_US",
          "name": "English (US)"
        }
      ],
      "name": "Some Other Bot"
    }
  ];
}
export { bots };

let app = {};
if(environment === 'development') {
  console.log('app being defined');
  app = {
    bot: 'PA1EQHHUXJ',
    updateEpisodeDialog: {
      open: false,
      episodeId: 0
    },
    API_ENDPOINT: `${process.env.REACT_APP_API_ENDPOINT}v${process.env.REACT_APP_API_VERSION}/admin/`
  }
}
export { app };

let episodes = [];
if(environment === 'development') {
  episodes = [{
        "createTime": 1624981427341,
        "id": "f4098dfa-bc94-478c-b5bc-204a53ffa630",
        "updateTime": 1624981427341,
        "confidence": 0.95,
        "helpful": false,
        "recording": {
            "answer": "Your blood pressure is recorded as two numbers:  Systolic blood pressure (the first number) – indicates how much pressure your blood is exerting against your artery walls when the heart beats. Diastolic blood pressure (the second number) – indicates how much pressure your blood is exerting against your artery walls while the heart is resting between beats.",
            "question": "hypertension"
        },
        "intent": {
            "closingResponses": ["Your blood pressure is recorded as two numbers:  Systolic blood pressure (the first number) – indicates how much pressure your blood is exerting against your artery walls when the heart beats. Diastolic blood pressure (the second number) – indicates how much pressure your blood is exerting against your artery walls while the heart is resting between beats."],
            "confirmationResponses": [],
            "metadata": {
                "id": "R5YA7OJGPJ",
                "name": "Hypertension-numbers"
            },
            "sampleUtterances": ["How do you know if you have blood pressure?", "What is considered as normal blood pressure?", "What is considered as stage 1 hypertension?", "What is stage 2 hypertension?", "Hypertension facts", "ideal systolic and diastolic numbers"]
        }
    }, {
        "createTime": 1624981681348,
        "id": "f37d7ec3-963a-4076-a9db-023e0ef9132a",
        "updateTime": 1624981681348,
        "confidence": 0.91,
        "helpful": false,
        "recording": {
            "answer": "I am AskKardia, an AI bot that can help you answer any questions about AliveCor Kardia Care programs. I can get answers for most of the questions related to Hypertension ,  AFib /Arrhythmia,  Kardia 6L , and AliveCor. Keep in mind I am not a google to answer anything and everything and I do not give out medical diagnosis information either. ",
            "question": "What is KardiaCard?"
        },
        "intent": {
            "closingResponses": ["I am AskKardia, an AI bot that can help you answer any questions about AliveCor Kardia Care programs. I can get answers for most of the questions related to Hypertension ,  AFib /Arrhythmia,  Kardia 6L , and AliveCor. Keep in mind I am not a google to answer anything and everything and I do not give out medical diagnosis information either. "],
            "confirmationResponses": [],
            "metadata": {
                "description": "About me",
                "id": "FOCHDMKODI",
                "name": "AboutAskKardia"
            },
            "sampleUtterances": ["Who are you?", "Who is AskKardia?", "What can AskKardia do?", "What do you do?", "What is Kardia", "what's kardia", "What's kardia", "What can you do", "Hey, what can you do", "What are you capable of?", "tell me what you do", "help?"]
        }
    }, {
        "createTime": 1624977649286,
        "id": "36029cdd-8f21-483d-b340-373c07d79d24",
        "updateTime": 1624977649286,
        "confidence": 1.0,
        "helpful": false,
        "recording": {
            "answer": "KardiaMobile 6L is portable and works wherever you are. You can take medical-grade EKGs from anywhere , anytime and get results in just 30 seconds",
            "question": "When can I use Kardia 6L?"
        },
        "intent": {
            "closingResponses": ["KardiaMobile 6L is portable and works wherever you are. You can take medical-grade EKGs from anywhere , anytime and get results in just 30 seconds"],
            "confirmationResponses": [],
            "metadata": {
                "id": "YIIJNGQ7E2",
                "name": "KardiaUsage"
            },
            "sampleUtterances": ["When can I use Kardia 6L?", "Can I use my device anywhere?", "Should I use the device at specific time?", "Can I carry my device anywhere?", "Is the device portable?"]
        }
    }, {
        "createTime": 1624981668592,
        "id": "0959e42a-c8ec-43b3-be7d-afebb20aac46",
        "updateTime": 1624981668592,
        "auditTrail": {
            "who": "someone"
        },
        "confidence": 0.96,
        "dueDiligence": "technical",
        "helpful": false,
        "recording": {
            "answer": "AliveCor vision is to be the 24/7 virtual cardiologist for patients when they are not in front of their physician",
            "question": "What products does AliveCor make?"
        },
        "status": "reviewed",
        "intent": {
            "closingResponses": ["Alivecor mission is to save lives and transform cardiology by delivering intelligent, personalized heart data to clinicians and patients anytime, anywhere. To find out more go to https://www.alivecor.com/"],
            "confirmationResponses": [],
            "metadata": {
                "description": "AliveCor",
                "id": "6EL1E0TAYQ",
                "name": "AboutAliveCor"
            },
            "sampleUtterances": ["What does AliveCor do?", "tell me more about AliveCor", "Does AliveCor sell EKg products?", "What does AliveCor sell?"]
        }
    }, {
        "createTime": 1624981654327,
        "id": "e515c728-8ced-470f-b677-c995e6534d57",
        "updateTime": 1624981654327,
        "confidence": 0.9,
        "helpful": false,
        "recording": {
            "answer": "AliveCor vision is to be the 24/7 virtual cardiologist for patients when they are not in front of their physician",
            "question": "Who is the CEO of AliveCor?"
        },
        "intent": {
            "closingResponses": ["Alivecor mission is to save lives and transform cardiology by delivering intelligent, personalized heart data to clinicians and patients anytime, anywhere. To find out more go to https://www.alivecor.com/"],
            "confirmationResponses": [],
            "metadata": {
                "description": "AliveCor",
                "id": "6EL1E0TAYQ",
                "name": "AboutAliveCor"
            },
            "sampleUtterances": ["What does AliveCor do?", "tell me more about AliveCor", "Does AliveCor sell EKg products?", "What does AliveCor sell?"]
        }
    }, {
        "createTime": 1624981692725,
        "id": "4ad7eb77-4287-4f98-98cc-12951b80abce",
        "updateTime": 1624981692725,
        "confidence": 0.89,
        "helpful": false,
        "recording": {
            "answer": "I am AskKardia, an AI bot that can help you answer any questions about AliveCor Kardia Care programs. I can get answers for most of the questions related to Hypertension ,  AFib /Arrhythmia,  Kardia 6L , and AliveCor. Keep in mind I am not a google to answer anything and everything and I do not give out medical diagnosis information either. ",
            "question": "What is KardiaMobile?"
        },
        "intent": {
            "closingResponses": ["I am AskKardia, an AI bot that can help you answer any questions about AliveCor Kardia Care programs. I can get answers for most of the questions related to Hypertension ,  AFib /Arrhythmia,  Kardia 6L , and AliveCor. Keep in mind I am not a google to answer anything and everything and I do not give out medical diagnosis information either. "],
            "confirmationResponses": [],
            "metadata": {
                "description": "About me",
                "id": "FOCHDMKODI",
                "name": "AboutAskKardia"
            },
            "sampleUtterances": ["Who are you?", "Who is AskKardia?", "What can AskKardia do?", "What do you do?", "What is Kardia", "what's kardia", "What's kardia", "What can you do", "Hey, what can you do", "What are you capable of?", "tell me what you do", "help?"]
        }
    }, {
        "createTime": 1624981891448,
        "id": "f47b1ea5-a1f9-4421-afdc-c42f71117a76",
        "updateTime": 1624981891448,
        "confidence": 0.85,
        "helpful": false,
        "recording": {
            "answer": "Hyperkalemia is the medical term that describes a potassium level in your blood that's higher than normal. Potassium is a chemical that is critical to the function of nerve and muscle cells, including those in your heart. Your blood potassium level is normally 3.6 to 5.2 millimoles per liter (mmol/L)",
            "question": "what should I do if my BP is low"
        },
        "intent": {
            "closingResponses": ["Hyperkalemia is the medical term that describes a potassium level in your blood that's higher than normal. Potassium is a chemical that is critical to the function of nerve and muscle cells, including those in your heart. Your blood potassium level is normally 3.6 to 5.2 millimoles per liter (mmol/L)"],
            "confirmationResponses": [],
            "metadata": {
                "id": "1MYNUNA4FF",
                "name": "Hyperkalemia"
            },
            "sampleUtterances": ["What is Hyperkalemia", "What's hyperkalemia", "How do I know if I have hyperkalemia", "My potassium level is high. Is that a concern?", "Should I be concerned if my potassium level is high?"]
        }
    }, {
        "createTime": 1624981904123,
        "id": "a22221ba-bd17-4ab9-b3b9-9b0d2b1154b3",
        "updateTime": 1624981904123,
        "confidence": 0.74,
        "helpful": false,
        "recording": {
            "answer": "Hypertension is often referred  as  HBP ( High Blood Pressure). A condition in which the force of the blood against the artery walls is too high. Usually hypertension is defined as blood pressure above 140/90, and is considered severe if the pressure is above 180/120. High blood pressure often has no symptoms. Over time, if untreated, it can cause health conditions, such as heart disease and stroke.",
            "question": "I have high BP. What should I do?"
        },
        "intent": {
            "closingResponses": ["Hypertension is often referred  as  HBP ( High Blood Pressure). A condition in which the force of the blood against the artery walls is too high. Usually hypertension is defined as blood pressure above 140/90, and is considered severe if the pressure is above 180/120. High blood pressure often has no symptoms. Over time, if untreated, it can cause health conditions, such as heart disease and stroke."],
            "confirmationResponses": [],
            "metadata": {
                "id": "ELY63PW69Z",
                "name": "Hypertension"
            },
            "sampleUtterances": ["what is Hypertension?", "what is hypertension?", "What is Htn", "What is hypertension", "what is htn", "what's hypertension?", "Hypertenshun", "Is Htn same as Hypertension?", "Is Hypertension same as High blood pressure?", "Is Htn same as high blood pressure", "Is Htn and Hypertension one and the same", "what is the difference between Htn and Hypertension", "Whats the difference between high blood pressure and Hypertension", "What is High Blood Pressure?", "My blood pressure is high. What does that mean?", "what is HPB", "what is hipertensun", "what is hypertensun", "what is hypertenshun"]
        }
    }, {
        "createTime": 1624981463400,
        "id": "c59bb71c-ad36-4e4c-ae39-d049a160462b",
        "updateTime": 1624981463400,
        "confidence": 0.86,
        "helpful": false,
        "recording": {
            "answer": "Hyperkalemia is the medical term that describes a potassium level in your blood that's higher than normal. Potassium is a chemical that is critical to the function of nerve and muscle cells, including those in your heart. Your blood potassium level is normally 3.6 to 5.2 millimoles per liter (mmol/L)",
            "question": "what should I do if I have hypertension?"
        },
        "intent": {
            "closingResponses": ["Hyperkalemia is the medical term that describes a potassium level in your blood that's higher than normal. Potassium is a chemical that is critical to the function of nerve and muscle cells, including those in your heart. Your blood potassium level is normally 3.6 to 5.2 millimoles per liter (mmol/L)"],
            "confirmationResponses": [],
            "metadata": {
                "id": "1MYNUNA4FF",
                "name": "Hyperkalemia"
            },
            "sampleUtterances": ["What is Hyperkalemia", "What's hyperkalemia", "How do I know if I have hyperkalemia", "My potassium level is high. Is that a concern?", "Should I be concerned if my potassium level is high?"]
        }
    }, {
        "createTime": 1624981576191,
        "id": "588ad526-9c3b-4da7-8608-f43b916b3834",
        "updateTime": 1624981576191,
        "confidence": 0.93,
        "helpful": false,
        "recording": {
            "answer": "Your blood pressure is recorded as two numbers:  Systolic blood pressure (the first number) – indicates how much pressure your blood is exerting against your artery walls when the heart beats. Diastolic blood pressure (the second number) – indicates how much pressure your blood is exerting against your artery walls while the heart is resting between beats.",
            "question": "Hypertension next steps?"
        },
        "intent": {
            "closingResponses": ["Your blood pressure is recorded as two numbers:  Systolic blood pressure (the first number) – indicates how much pressure your blood is exerting against your artery walls when the heart beats. Diastolic blood pressure (the second number) – indicates how much pressure your blood is exerting against your artery walls while the heart is resting between beats."],
            "confirmationResponses": [],
            "metadata": {
                "id": "R5YA7OJGPJ",
                "name": "Hypertension-numbers"
            },
            "sampleUtterances": ["How do you know if you have blood pressure?", "What is considered as normal blood pressure?", "What is considered as stage 1 hypertension?", "What is stage 2 hypertension?", "Hypertension facts", "ideal systolic and diastolic numbers"]
        }
    }];
}
export { episodes };
