import { createAsyncThunk as thunk, createSlice } from '@reduxjs/toolkit';
const getjwt = async (data) => {
  const { email, password } = data;
  const basicToken = btoa(unescape(encodeURIComponent(`${email}:${password}`)));
  const getConfig = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Basic ${basicToken}`,
    },
  };
  const userResp = await fetch(
    'https://us-kardia-k8s.development.alivecor.net/i/v1/jwt',
    getConfig
  );
  const user = await userResp.json();
  let finalBody = {};

  localStorage.setItem('jwt', JSON.stringify(user.jwt));
  return { jwt: user.jwt };
};

export const authUser = thunk('auth/get', getjwt);

export const auth = createSlice({
  name: 'Auth',
  initialState: {},
  reducers: {},
  extraReducers: {
    [authUser.fulfilled]: (state, action) => {
      return { jwt: action.payload.jwt };
    },
    [authUser.rejected]: (state, action) => {
      return { error: 'Invalid Credentials, Please try again.' };
    },
  },
});

export default auth.reducer;
