import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../../store/user';
import { WELCOME } from '../../constants';
import styles from './index.module.css';

const User = () => {
  const dispatch = useDispatch();
  const user = useSelector(store => store.user);

  useEffect(() => { dispatch(getUser()) }, [dispatch]);
  return (<div className={styles.user}>{WELCOME}, {user.firstName} {user.lastName}</div>);
}

export default User;