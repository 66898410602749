import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { getBots } from '../../store/bots';
import { getBots } from '../../data/bots';
// import { botChosen } from '../../store/app';
import styles from './index.module.css';
import { app as appInitialState } from '../../store/initialState';

const Bots = () => {
  // const dispatch = useDispatch();
  // const bots = useSelector(store => store.bots);
  // const chosenBot = useSelector(store => store.app.bot);
  const [chosenBot, updateChosenBot] = useState(appInitialState.bot);
  const [bots, updateBots] = useState([]);
  const onClick = (id, event) => {
    event.preventDefault();
    // dispatch(botChosen({id}));
    updateChosenBot(id);
    console.log(this.state.chosenBot);
  }
  useEffect(() => {
    (async function() {
      try {
        const bots = await getBots();
        updateBots(bots||[]);
      } catch(e) { }
    })();
    
  }, [updateBots]);
  // useEffect(() => { dispatch(getBots()) }, [dispatch]);
  return (
    <div className={styles.bots}>
      <div className={styles.title}>bots</div>
      {bots.map((bot, index) => (<div key={index} className={`${bot.id===chosenBot?styles.active:''} ${styles.bot}`} onClick={onClick.bind(null, bot.id)}>{bot.name}</div>))}
    </div>
  )
}

export default Bots;