import { createSlice } from '@reduxjs/toolkit';
import { app as initialState } from './initialState';

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    botChosen: (state, action) => {
      console.log('setting chosen bot', action);
      state.bot = action.payload.id;
    },
    toggleEpisodeUpdateDialog: (state, action) => {
      console.log('setting episodeUpdateDialog', action);
      state.updateEpisodeDialog = action.payload;
    }
  }
});

export const { botChosen, toggleEpisodeUpdateDialog } = slice.actions;

export default slice.reducer;
