import React, { useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { AppBar as MuiAppBar, Tooltip } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import LoadingButton from "../common/LoadingButton";
import InputBase from "@material-ui/core/InputBase";
import { useHistory } from 'react-router';
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { teach } from "../../data/bots";
import { app } from "../../store/initialState";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    width:'5rem',
  },
  fab: {
    margin: theme.spacing(2),
  },
  search: {
    width:'100%',
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
    flexGrow: 1,
    justifySelf:'center',
    borderRadius:'16px',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: "inherit",
  },
}));

const AppBar = (props) => {
  const history = useHistory();
  const [loading, updateLoadingState] = useState(false);
  const [searchText, setSearchText] = useState("");
  const classNames = useStyles();
  const teachBot = async (id, ev) => {
    console.log(ev, id);
    try {
      updateLoadingState(true);
      await teach(id);
      updateLoadingState(false);
    } catch (e) {
      console.error(e);
    }
  };
  const searchHandler = (val) => {
    setSearchText(val);
    props.searchEpisodes(val);
  };
  const logoutHandler=()=>{
    localStorage.removeItem('jwt');
    window.location.href='/'
  }
  return (
    <MuiAppBar position="static">
      <Toolbar>
        <Typography variant="h6" className={classNames.title}>
          Episodes
        </Typography>
        <Tooltip title="Add" aria-label="add">
          <Fab color="info" className={classNames.fab} onClick={props.createNewIntent}>
            <AddIcon />
          </Fab>
        </Tooltip>
        <div className={classNames.search}>
        <Tooltip title="Global search for question, answer, intent name, due diligence, status" aria-label="add"><div className={classNames.searchIcon}>  <SearchIcon/></div></Tooltip>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classNames.inputRoot,
              input: classNames.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            value={searchText}
            onChange={(e) => searchHandler(e.target.value)}
          />
          
        </div>
        {/* <LoadingButton color="inherit" label="Add Intent" onClick={props.createNewIntent}>Update Bot</LoadingButton> */}
       
        <LoadingButton
          loading={loading}
          onClick={teachBot.bind(null, app.bot)}
          variant="outlined"
          label="Teach"
        >
          Submit
        </LoadingButton>
        <Tooltip title="Logout" aria-label="logout">
          <Fab color="info" className={classNames.fab} onClick={()=>logoutHandler()}>
            <ExitToAppIcon />
          </Fab>
        </Tooltip>
      </Toolbar>
    </MuiAppBar>
  );
};
export default AppBar;
