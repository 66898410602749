import { configureStore } from '@reduxjs/toolkit';
import episodesReducer from './episodes';
import usersReducer from './users';
import userReducer from './user';
import botsReducer from './bots'
import appReducer from './app'
import authReducer from './auth'

export default configureStore({
  devTools: true,
  reducer: {
    auth:authReducer,
    episodes: episodesReducer,
    users: usersReducer,
    user: userReducer,
    bots: botsReducer,
    app: appReducer,
  }
});
