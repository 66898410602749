import { createAsyncThunk as thunk, createSlice } from '@reduxjs/toolkit';

const getUsersRemote = async () => {
  const usersResp = await fetch('https://reqres.in/api/users');
  const users = await usersResp.json();
  return { users: users.data };
}
export const getUsers = thunk('users/get', getUsersRemote);

export const users = createSlice({
  name: 'Users',
  initialState: [],
  reducers: {},
  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      return action.payload.users;
    }
  }
});

export default users.reducer;