import { API_ENDPOINT, GET_CONFIG, POST_CONFIG, /* PUT_CONFIG, */ handleErrors } from './config';

const base = `${API_ENDPOINT}bots/`;

export const getBots = async (id) => {
  let jwt = JSON.parse(localStorage.getItem('jwt'))
  const config = {...GET_CONFIG, headers: {Accept: 'application/json',Authorization: `Bearer ${jwt}`}};
  const botsResp = await fetch(`${base}${id||''}`, config).then(handleErrors);
  const bots = await botsResp.json();
  return bots;
};

export const teach = async (id) => {
  let jwt = JSON.parse(localStorage.getItem('jwt'))
  const config = {...POST_CONFIG, headers: {Accept: 'application/json','Content-Type':'application/json',Authorization: `Bearer ${jwt}`}};
  const teachResp = await fetch(`${base}${id}/regions/en_US/train`, config).then(handleErrors);
  return await teachResp.json();
}
