import "../App.css";
import { useState, useEffect } from "react";
import Episodes from "../components/episodes";
import Sidebar from "../components/sidebar";
import AppBar from "../components/appbar";
import Update from "../components/episodes/update";
import {
  getEpisodes,
  episodeModel,
  mapEpisodesForColumns,
  mapEpisodeForColumns,
  createEpisode,
} from "../data/episodes";
/*import { updateEpisode } from './store/episodes';*/
import { updateEpisode } from "../data/episodes";
import { makeStyles } from "@material-ui/core/styles";
/*import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react'; */

const stylesToUse = makeStyles({
  App: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
  },
});

function EpisodeScreen() {
  /* const dispatch = useDispatch();*/
  const classNames = stylesToUse();
  const [open, updateOpen] = useState(false);
  const [episodesLoading, updateEpisodesLoading] = useState(true);
  const [episodeChosen, updateEpisodeChosen] = useState(episodeModel);
  const [episodes, updateEpisodesState] = useState([]);
  const [filteredEpisodes, setFilteredEpisodes] = useState([]);
  const [episodeUpdateErrors, updateEpisodeUpdateErrors] = useState([]);
  const [newIntent, setNewIntent] = useState(false);
  const updateEpisodeChosenProxy = (episode) => {
    updateEpisodeChosen(episode);
  };
  useEffect(() => {
    updateEpisodeChosen(episodeModel);
  }, [newIntent]);
  useEffect(() => {
    (async function () {
      try {
        const episodes = await getEpisodes();
        updateEpisodesState(mapEpisodesForColumns(episodes || []));
        setFilteredEpisodes(mapEpisodesForColumns(episodes || [])); // filtering episodes with ''
        updateEpisodesLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [updateEpisodesState]);
  const submitUpdateEpisode = async (episode) => {
    try {
      updateEpisodeUpdateErrors([]);
      await updateEpisode(episode);
      const episodeForColumns = mapEpisodeForColumns(episode);
      updateEpisodesState(
        episodes.map((e) =>
          e.id === episodeForColumns.id ? episodeForColumns : e
        )
      );
      updateOpen(false);
      updateEpisodeUpdateErrors([]);
    } catch (e) {
      updateEpisodeUpdateErrors(["Update Failed"]);
    }
  };
  const submitCreateEpisode = async (episode) => {
    try {
      updateEpisodeUpdateErrors([]);
      await createEpisode(episode);
      const episodeForColumns = mapEpisodeForColumns(episode);
      updateEpisodesState(
        episodes.map((e) =>
          e.id === episodeForColumns.id ? episodeForColumns : e
        )
      );
      updateOpen(false);
      updateEpisodeUpdateErrors([]);
    } catch (e) {
      updateEpisodeUpdateErrors(["Create Failed"]);
    }
  };
  const createNewIntentHandler = () => {
    console.log("new intent!");
    setNewIntent(true);
    updateEpisodeChosen(episodeModel);
    updateOpen(true);
  };
  const searchEpisodes = (text) => {
    // console.log(episodes);
    console.log(text)
    let searchInput = text.toLowerCase();
    let filteredData = episodes.filter(
      (e) =>
        e.question.toLowerCase().includes(searchInput) ||
        e.answer.toLowerCase().includes(searchInput) ||
        e.intentName.toLowerCase().includes(searchInput)||
        e.dueDiligence?.toLowerCase().includes(searchInput)||
        e.status?.toLowerCase().includes(searchInput)
    );
    setFilteredEpisodes(filteredData);
    // console.log(filteredData)
  };
  /* const getEpisode = id => updateEpisodeChosen(episodes.find(episode => episode.id === id));
  useEffect(() => {

  }, [episodeChosen]) */
  /*const updateEpisode = (episode) => {
    dispatch(updateEpisode(episode));
  }
  const { episodeId, open } = useSelector(store => store.app.updateEpisodeDialog);
  const episodeOriginal = useSelector(store => store.episodes.find(episode => episode.id === episodeId)) || {};
  const episode = {...episodeOriginal}; */
  // const [episodeUpdate, updateEpisode] = useState(episodeOriginal);
  return (
    <div className={classNames.App}>
      <Sidebar />
      <main style={{ align: "center" }} className={classNames.content}>
        {/* <Container maxWidth="lg"> */}
        <AppBar
          createNewIntent={createNewIntentHandler}
          searchEpisodes={searchEpisodes}
        />
        <Episodes
          episodes={filteredEpisodes}
          updateEpisodeChosen={updateEpisodeChosenProxy}
          updateOpen={updateOpen}
          loading={episodesLoading}
        />
        {/* </Container> */}
        <Update
          open={open}
          episode={episodeChosen}
          updateOpen={updateOpen}
          update={submitUpdateEpisode}
          create={submitCreateEpisode}
          isNew={newIntent}
          errors={episodeUpdateErrors}
        />
      </main>
    </div>
  );
}

export default EpisodeScreen;

